// Display.js

import React, { useState } from 'react';
import './Display.css';

function Display({ territories }) {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const hasResponse = territories.length > 0;
  const showNoResponseMessage = formSubmitted && !hasResponse;

  return (
    <div className="output-container">
      {hasResponse ? (
        territories.map((territory, index) => (
          <div key={index} className="output-bubble">
            <p>{territory}</p>
          </div>
        ))
      ) : null}
      {showNoResponseMessage && (
        <div className="no-response-message">Sorry! No response</div>
      )}
    </div>
  );
}

export default Display;
