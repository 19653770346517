// App.js

import React, { useState } from 'react';
import Input from './Input';
import Display from './Display';
import AdBanner from './AdBanner'; // Import the ad banner component
import Header from "./components/Header";
import './App.css';
import backgroundImage from './assets/bg-1.png';

const headerData = {
  title: "ON STOLEN LAND",
  status: ""
}

const App = () => { 
  const [territories, setTerritories] = useState([]);

  return (
    <div className="App">
      <div className="top-panel">
        <Header headerData={headerData} />
        <AdBanner />
        <Input setTerritories={setTerritories} />
      </div>
      <div className="display-panel" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Display territories={territories} />
      </div>
    </div>
  );
}

export default App;