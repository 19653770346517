// Geolocation.js

import axios from 'axios';

export const getLatLon = async (input) => {
  try {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${input}`
    );

    if (response.data && response.data.length > 0) {
      const { lat, lon } = response.data[0];
      return { lat, lon };
    } else {
        return { lat: 0, lon: 0 }; // Return default values or an empty object
    }
  } catch (error) {
    console.error('Error fetching latitude and longitude:', error);
    return { lat: 0, lon: 0 }; // Return default values or an empty object
  }
};