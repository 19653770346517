// Header.js

import logo from '../assets/osl-icon.png';

const Header = (props) => {
    return (<>
        <div className="chat__header">
            <img src={logo} alt="OSL Logo" className="header-logo" />
            <h2 className="chat__header__title">{props.headerData.title}</h2>
            <p className="chat__header__status">{props.headerData.status}</p>
        </div>
    </>);
}
export default Header;