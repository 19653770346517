// Input.js

import React, { useState } from 'react';
import axios from 'axios';
import * as turf from '@turf/turf';
import data from './tribe_data.json';
import { getLatLon } from './geolocation'; // Import the getLatLon function

function Input({ setTerritories }) {
  const [input, setInput] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [foundTerritories, setFoundTerritories] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (input.trim() === '') {
      return; // Skip form submission if input is empty or contains only whitespace
    }

    // Get lat, lon from OpenStreetMap Nominatim API
    const response = await getLatLon(input);
    const { lat, lon } = response || { lat: 0, lon: 0 };

    // Determine which tribal areas contain the lat, lon
    const point = turf.point([parseFloat(lon), parseFloat(lat)]);
    const foundTerritories = [];

    for (let feature of data.features) {
      const { type, coordinates } = feature.geometry;
      let polygons = [];

      if (type === 'Polygon') {
        polygons = [turf.polygon(coordinates)];
      } else if (type === 'MultiPolygon') {
        polygons = coordinates.map((coord) => turf.polygon(coord));
      }

      for (let polygon of polygons) {
        if (turf.booleanPointInPolygon(point, polygon)) {
          foundTerritories.push(feature.properties.Name);
          break;
        }
      }
    }

    // Update territories in App component
    setTerritories(foundTerritories);
    setFormSubmitted(true);
    setFoundTerritories(foundTerritories);

    // Clear the input field
    setInput('');
  };

  return (
    <div className="input-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          placeholder='Enter address, city or zipcode'
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit">?</button>
      </form>
      {formSubmitted && foundTerritories.length === 0 && (
          <p className="no-response-message">Sorry! No data found!.</p>
      )}
    </div>
  );
}

export default Input;
