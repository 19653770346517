// AdBanner.js

import React from 'react';
import './AdBanner.css';

const AdBanner = () => {
  return (
    <div className="ad-banner">
      <p>Your Ad Here</p>
    </div>
  );
};

export default AdBanner;
